import React, { useEffect} from "react";
import Header from './components/common/Header';
import Home from './pages/Home';
import './App.css';

const App = () => {
    useEffect(() => {
        document.title = "bitquest";  
      }, []);

    return (
        <div className="App">
            <Header />
            <Home />
        </div>
    );
};

export default App;
